<template>
    <div>
        <p class="d-flex align-center">
            <a @click="clickUpdateInfo">
                <v-icon class="update-info-icon">restart_alt</v-icon> Atualização realizada
            </a>
            <span>
                em {{ lastProcessData }}
            </span>
        </p>
    </div>
</template>

<script>

export default {
    name: 'UpdateInfo',

    props: {
        lastProcessData: {
            type: String,
            required: true
        }
    },

    methods: {
        clickUpdateInfo(e) {
            e.preventDefault();
            this.$emit('clickUpdateInfo', e);
        }
    }

}

</script>

<style lang="scss" scoped>
    @import './src/design/variables.scss';

    a {
        font-size: $font-size-xxxs;
        font-weight: $font-weight-regular;
        color: $feedback-color-information-dark;
        .update-info-icon {
            padding: 0;
            margin-bottom: 1px;
            color: $feedback-color-information-dark;
            font-size: $font-size-xs;
        }
    }
    p {
        color: $neutral-color-high-medium;
        font-size: $font-size-xxxs;
        font-weight: $font-weight-regular;
        span {
            margin-left: 2px;
        }
    }
</style>