var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", { staticClass: "d-flex align-center" }, [
      _c(
        "a",
        { on: { click: _vm.clickUpdateInfo } },
        [
          _c("v-icon", { staticClass: "update-info-icon" }, [
            _vm._v("restart_alt"),
          ]),
          _vm._v(" Atualização realizada "),
        ],
        1
      ),
      _c("span", [_vm._v(" em " + _vm._s(_vm.lastProcessData) + " ")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }